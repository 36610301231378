<p class="position-relative">
  <mat-toolbar color="accent">
    <div class="wrapper-logo mr-3">
      <a [routerLink]="['/']">
        <img
          [src]="ImagesConfig.toolbar"
          [alt]="AppConfig.name"
          class="img-fluid img-presco"
        />
      </a>
    </div>
    <div class="wrapper-logo mr-3 d-none d-sm-block">
      <a [routerLink]="['/']" class="text-reset text-decoration-none">{{
        AppConfig.name + " - " + ("Ayuntamiento" | translate)
      }}</a>
    </div>
    <!-- <a [routerLink]="['/']" class="text-reset text-decoration-none">{{AppConfig.name}}</a> -->

    <span class="qaroni-toolbar-spacer"></span>

    <qaroni-change-language></qaroni-change-language>
  </mat-toolbar>
  <mat-progress-bar
    *ngIf="progressBar$ | async"
    color="primary"
    mode="indeterminate"
  ></mat-progress-bar>
</p>
