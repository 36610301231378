import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatusEnum } from '@qaroni-app/application/auth';
import { CardService } from '@qaroni-app/application/cards/services/card.service';
import { Card } from '@qaroni-app/application/cards/types/card';
import { CardDataSource } from '@qaroni-app/application/cards/types/card-datasource';
import { CardPdfDataDialog } from '@qaroni-app/application/cards/types/card-pdf-data-dialog';
import { Client, ClientService } from '@qaroni-app/application/clients';
import { CardTypeEnum } from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { ConfirmationDialogData } from '@qaroni-core/types';
import { CardTypePipe } from '@qaroni-shared/pipes/cards/card-type.pipe';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../dialogs';
import { CardPdfDialogComponent } from '../dialogs/card-pdf-dialog/card-pdf-dialog.component';

@Component({
  selector: 'qaroni-cards-table',
  templateUrl: './cards-table.component.html',
  styleUrls: ['./cards-table.component.scss'],
})
export class CardsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  public cards$: Observable<Card[]> = this.cardService
    .getCards$()
    .pipe(shareReplay(1));

  @Input() client: Client;
  @Input() showPaginator = true;
  showTable = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Card>;
  public dataSource: CardDataSource;

  public displayedColumns: string[] = [
    'creationDate',
    'lastUpdateDate',
    'type',
    'isStatic',
    'balance',
    'actions',
  ];

  public UserStatusEnum = UserStatusEnum;

  private subs: Subscription = new Subscription();
  submitting = false;

  constructor(
    private allApp: AllAppService,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private cardService: CardService,
    private cardTypePipe: CardTypePipe
  ) {}

  ngOnInit(): void {
    this.dataSource = new CardDataSource(
      this.route,
      this.router,
      this.cardService
    );
    this.subs.add(this.cardService.getCards$().subscribe(this.getCards));
    this.subs.add(this.cardService.getCard$().subscribe(this.getCard));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private enableLoading(): void {
    this.submitting = true;
    this.allApp.progressBar.hide();
  }

  private disableLoading(): void {
    this.submitting = false;
    this.allApp.progressBar.hide();
  }

  private getCards = (cards: Card[]): void => {
    this.disableLoading();
    if (cards && cards.length > 0) {
      this.showTable = true;
    }
  }

  public isCardStatic(card: Card): boolean {
    return this.cardService.isCardStatic(card);
  }

  public downloadPdf(card: Card): void {
    if (
      this.client &&
      this.client.clientId &&
      card &&
      card.cardId &&
      card.wallet
    ) {
      // Downloads.
      if (this.isCardStatic(card)) {
        const url = this.cardService.getDownloadPdf(
          this.client.clientId,
          card?.cardId,
          card?.wallet
        );
        window.open(url, '_blank');
        this.refreshCardsTable(this.client.clientId);
      }
      // Does activation.
      else {
        this.onQrPdf(this.client, card);
      }
    }
  }

  public onQrPdf(client: Client, card: Card): void {
    const url: string = this.cardService.getDownloadPdf(
      client.clientId,
      card.cardId,
      card.wallet
    );

    const message = this.allApp.translate.instant(
      'Confirmas que deseas Activar el Bono de '
    );
    const finalMessage = this.allApp.translate.instant(' en un archivo PDF');

    const confirmationData: CardPdfDataDialog = {
      title: this.allApp.translate.instant('¿Activar el Bono en PDF?'),
      message:
        message +
        client?.firstName +
        ' ' +
        client?.lastName +
        ' - ' +
        client?.document +
        finalMessage +
        '?',
      downloadUrl: url,
      confirmMatIcon: `qr_code`,
      confirmText: this.allApp.translate.instant('Sí, activar'),
    };

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '700px';
    matDialogConfig.maxWidth = '90vw';
    matDialogConfig.panelClass = 'style-close-dialog';
    matDialogConfig.data = confirmationData;

    const dialog = this.allApp.dialog.open<
      CardPdfDialogComponent,
      CardPdfDataDialog,
      boolean
    >(CardPdfDialogComponent, matDialogConfig);

    this.subs.add(
      dialog.afterClosed().subscribe((confirmation) => {
        if (confirmation && client.clientId) {
          this.refreshCardsTable(client.clientId);
        }
      })
    );
  }

  public refreshWallet(card: Card): void {
    if (this.client && this.client.clientId && card?.cardId && card?.wallet) {
      this.openDialogConfirmationToRefreshWallet(
        this.client.clientId,
        card.cardId
      );
    }
  }

  openDialogConfirmationToRefreshWallet(
    clientId: number,
    cardId: number
  ): void {
    const confirmationData: ConfirmationDialogData = {
      title: this.allApp.translate.instant('Refrescar wallet'),
      message: this.allApp.translate.instant(
        'Confirmas que deseas refrescar el wallet?'
      ),
      confirmMatIcon: `refresh`,
      confirmText: this.allApp.translate.instant('Sí, refrescar'),
    };

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '700px';
    matDialogConfig.maxWidth = '90vw';
    matDialogConfig.panelClass = 'style-close-dialog';
    matDialogConfig.data = confirmationData;

    const dialog = this.allApp.dialog.open(
      ConfirmationDialogComponent,
      matDialogConfig
    );

    this.subs.add(
      dialog.afterClosed().subscribe((confirmation) => {
        if (confirmation === true) {
          this.enableLoading();
          this.cardService.refreshWallet(clientId, cardId);
        }
      })
    );
  }

  private getCard = (card: Card): void => {
    this.disableLoading();
  }

  getIconNameByCardType(card: Card): string {
    if (card && card.type) {
      if (card.type === CardTypeEnum.CULTURE) {
        return 'chrome_reader_mode';
      } else if (card.type === CardTypeEnum.HOSTELRY) {
        return 'local_drink';
      } else if (card.type === CardTypeEnum.SHOP) {
        return 'location_city';
      } else if (card.type === CardTypeEnum.SALON) {
        return 'card_travel';
      } else if (card.type === CardTypeEnum.TAXI) {
        return 'local_taxi';
      }
    } else {
      return 'store';
    }
  }

  getAcctionIconByCard(card: Card): string {
    return this.isCardStatic(card) ? 'cloud_download' : 'qr_code';
  }

  getColor(card: Card): string {
    return this.isCardStatic(card) ? 'light-blue' : 'light-green';
  }

  getAcctionPdfTitle(card: Card): string {
    return this.isCardStatic(card)
      ? this.allApp.translate.instant('Descargar Bono en PDF')
      : this.allApp.translate.instant('Activar Bono en PDF');
  }

  refreshCardsTable(clientId: number): void {
    setTimeout(() => {
      this.enableLoading();
      this.cardService.getCards(clientId);
    }, 1000);
  }

  viewTransactionsByCardId(card: Card): void {
    if (card && card.cardId && this.client && this.client.clientId) {
      // Set client info.
      let clientInfo = '';
      if (this.client.document) {
        clientInfo = this.client.document;
      }
      if (this.client.firstName) {
        clientInfo += ' - ' + this.client.firstName;
      }
      if (this.client.lastName) {
        clientInfo += ' ' + this.client.lastName;
      }

      if (clientInfo && clientInfo.length > 0) {
        this.clientService.setClientInfo(clientInfo);
      } else {
        this.clientService.setClientInfo(null);
      }

      // Set card name.
      let cardName = this.cardTypePipe.transform(card.type as CardTypeEnum);
      if (cardName) {
        cardName = this.allApp.translate.instant(cardName);
        this.cardService.setCardName(cardName);
      } else {
        this.cardService.setCardName(null);
      }

      const route = `dashboard/clients/${this.client.clientId}/transactions`;
      this.allApp.router.navigate([route], {
        queryParams: { cardId: card.cardId },
      });
    }
  }
}
