import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Transaction,
  TransactionOperation,
  TransactionTypeEnum
} from '@qaroni-app/application/transactions';
import { TransactionService } from '@qaroni-app/application/transactions/services/transaction.service';
import { Merchant, MerchantTypeEnum } from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { InputValidation } from '@qaroni-core/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qaroni-paying-dialog',
  templateUrl: './paying-dialog.component.html',
  styleUrls: ['./paying-dialog.component.scss'],
})
export class PayingDialogComponent implements OnInit, OnDestroy {
  public maxAmount: number;

  public payingForm: FormGroup;
  private payingSkeleton = {
    amount: [''],
    description: ['', Validators.required],
    authorizationCode: ['', Validators.required],
  };

  private submitting = false;

  private subs: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<PayingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public merchant: Merchant,
    private allApp: AllAppService,
    private fb: FormBuilder,
    private transactionService: TransactionService
  ) {
    this.setMaxAmount();
    this.createPayingForm();
  }

  ngOnInit(): void {
    this.subs.add(
      this.transactionService.getTransaction$().subscribe(this.getTransaction)
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private enableLoading(): void {
    this.allApp.progressBar.show();
    this.submitting = true;
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
    this.submitting = false;
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  private setMaxAmount(): void {
    if (this.merchant?.type === MerchantTypeEnum.CULTURE) {
      this.maxAmount = 5000;
    } else if (this.merchant?.type === MerchantTypeEnum.HOSTELRY) {
      this.maxAmount = 3000;
    } else if (this.merchant?.type === MerchantTypeEnum.SHOP) {
      this.maxAmount = 3000;
    } else if (this.merchant?.type === MerchantTypeEnum.SALON) {
      this.maxAmount = 5000;
    } else if (this.merchant?.type === MerchantTypeEnum.TAXI) {
      this.maxAmount = 5000;
    }
  }

  private createPayingForm(): void {
    this.payingForm = this.fb.group(this.payingSkeleton);
    this.amount.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(this.maxAmount),
    ]);
  }

  get InputValidation(): any {
    return InputValidation;
  }

  get amount(): AbstractControl {
    return this.payingForm.get('amount');
  }

  get description(): AbstractControl {
    return this.payingForm.get('description');
  }

  get authorizationCode(): AbstractControl {
    return this.payingForm.get('authorizationCode');
  }

  get validatedForm(): boolean {
    return this.payingForm.dirty && this.payingForm.valid && !this.submitting;
  }

  public onSubmit(): void {
    if (this.validatedForm && this.merchant?.merchantId) {
      this.enableLoading();
      const transaction: TransactionOperation = {
        type: TransactionTypeEnum.ADD,
        amount: parseFloat(this.amount.value) * 100,
        description: this.description.value,
        authorizationCode: this.authorizationCode.value,
      };
      this.transactionService.createTransaction(
        this.merchant.merchantId,
        transaction
      );
    }
  }

  private getTransaction = (transaction: Transaction): void => {
    this.disableLoading();
    if (transaction) {
      this.dialogRef.close(transaction);
    }
  }
}
