import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardTypePipe } from './cards/card-type.pipe';
import { ChargeStatusPipe } from './charge-status.pipe';
import { IntegerToCurrencyPipe } from './currency/integer-to-currency.pipe';
import { MerchantTypePipe } from './merchant/merchant-type.pipe';
import { TransactionResultPipe } from './transaction/transaction-result.pipe';
import { TransactionTypePipe } from './transaction/transaction-type.pipe';
import { UserStatusPipe } from './user/user-status.pipe';

@NgModule({
  declarations: [
    CardTypePipe,
    ChargeStatusPipe,
    IntegerToCurrencyPipe,
    MerchantTypePipe,
    TransactionResultPipe,
    TransactionTypePipe,
    UserStatusPipe,
  ],
  imports: [CommonModule],
  exports: [
    CardTypePipe,
    ChargeStatusPipe,
    IntegerToCurrencyPipe,
    MerchantTypePipe,
    TransactionResultPipe,
    TransactionTypePipe,
    UserStatusPipe,
  ],
  providers: [CardTypePipe, MerchantTypePipe],
})
export class PipesModule {}
