import { Component, OnInit } from '@angular/core';
import { TransactionService } from '@qaroni-app/application/transactions/services/transaction.service';
import { TotalAmounts } from '@qaroni-core/types';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'qaroni-total-amounts-taxi-cards',
  templateUrl: './total-amounts-taxi-cards.component.html',
  styleUrls: ['./total-amounts-taxi-cards.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class TotalAmountsTaxiCardsComponent implements OnInit {
  public amounts$: Observable<TotalAmounts> = this.transactionService
    .getTotalAmountsTaxi$()
    .pipe(shareReplay(1));

  constructor(private transactionService: TransactionService) {}

  ngOnInit(): void {}
}
