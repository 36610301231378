export enum CardTypeEnum {
  CULTURE = 'CULTURE',
  HOSTELRY = 'HOSTELRY',
  SHOP = 'SHOP',
  SALON = 'SALON',
  TAXI = 'TAXI',
}

export const CardTypeInfo = [
  {
    [CardTypeEnum.CULTURE]: {
      name: 'Cultura',
    },
  },
  {
    [CardTypeEnum.HOSTELRY]: {
      name: 'Hostelería',
    },
  },
  {
    [CardTypeEnum.SHOP]: {
      name: 'Comercio',
    },
  },
  {
    [CardTypeEnum.SALON]: {
      name: 'Estética',
    },
  },
  {
    [CardTypeEnum.TAXI]: {
      name: 'Taxi',
    },
  },
];
