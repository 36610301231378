<div class="container-fluid px-0">
  <div class="row no-gutters">
    <div class="col-12 bg-footer text-white py-2 py-0">
      <div class="container">
        <div
          class="
            row
            justify-content-center justify-content-sm-between
            align-items-center
          "
        >
          <div class="col-auto text-center">
            <img
              id="logo-owner"
              [src]="ImagesConfig.footerOwner"
              [alt]="ImagesConfig.footerOwnerAlt"
              class="img-fluid m-2"
            />
            <img
              id="logo-partner"
              [src]="ImagesConfig.footerPartner"
              [alt]="ImagesConfig.footerPartnerAlt"
              class="img-fluid m-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
