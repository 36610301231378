import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { Card } from '@qaroni-app/application/cards/types/card';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CardSnackbars } from '../types/card-snackbars.config';
import { CardHttpService } from './card-http.service';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  protected readonly cardSubject = new Subject<Card>();
  protected readonly cardsSubject = new Subject<Card[]>();
  public cardName: string = null;

  constructor(
    private allApp: AllAppService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService,
    private cardHttpService: CardHttpService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public setCardName(cardName: string): void {
    this.cardName = cardName;
  }

  public getCardName(): string {
    return this.cardName;
  }

  public isCardStatic(card: Card): boolean {
    return card && card.isStatic && card.isStatic === 1 ? true : false;
  }

  // ==========================================================================================
  // Get Download PDF
  // ==========================================================================================

  public getDownloadPdf(
    clientID: number,
    cardID: number | string,
    wallet: string
  ): string {
    if (this.oAuthStorage.hasOAuth) {
      return this.cardHttpService.buildDownloadPdfUrl(clientID, cardID, wallet);
    }
  }

  // ==========================================================================================
  // Get Cards
  // ==========================================================================================
  public getCards$(): Observable<Card[]> {
    return this.cardsSubject.asObservable();
  }

  public getCards(clientId: number | string, params?: Params): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.cardHttpService
        .getCards$(clientId, params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetCards,
          error: this.errorGetCards,
        });
    }
  }

  private nextGetCards = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const cards: Card[] = data.body.result;
      this.cardsSubject.next(cards);
    } else {
      this.cardsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetCards = (error: HttpErrorResponse): void => {
    this.cardsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Card
  // ==========================================================================================
  public getCard$(): Observable<Card> {
    return this.cardSubject.asObservable();
  }

  // ==========================================================================================
  // Refresh Wallet
  // ==========================================================================================
  public refreshWallet(
    clientID: number | string,
    cardID: number | string
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.cardHttpService
        .refreshWallet$(clientID, cardID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextRefreshWallet,
          error: this.errorRefreshWallet,
        });
    }
  }

  private nextRefreshWallet = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const card: Card = data.body.result[0];
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          CardSnackbars.successRefreshedWallet.message
        ),
        CardSnackbars.successRefreshedWallet.closeBtn,
        CardSnackbars.successRefreshedWallet.config
      );
      this.cardSubject.next(card);
    } else {
      this.cardSubject.next(null);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          CardSnackbars.failedRefreshedWallet.message
        ),
        CardSnackbars.failedRefreshedWallet.closeBtn,
        CardSnackbars.failedRefreshedWallet.config
      );
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorRefreshWallet = (error: HttpErrorResponse): void => {
    this.cardSubject.next(null);
    this.allApp.snackbar.open(
      this.allApp.translate.instant(
        CardSnackbars.failedRefreshedWallet.message
      ),
      CardSnackbars.failedRefreshedWallet.closeBtn,
      CardSnackbars.failedRefreshedWallet.config
    );
    this.commonsHttp.errorsHttp.communication(error);
  }
}
