import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';
import { DataToGenerateReportVO } from '../components/models/DataToGenerateReportVO';
import { Client } from '../types/client';

@Injectable({
  providedIn: 'root',
})
export class ClientHttpService {
  constructor(private http: HttpClient) {}

  public getClient$(clientID: number | string): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public getClients$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients`;

    return this.http.get(url, { observe: 'response', params });
  }

  public updateClient$(
    clientID: number | string,
    client: Client
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}`;

    return this.http.patch(url, client, { observe: 'response' });
  }

  public cancelClient$(
    clientID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cancels`;

    return this.http.patch(url, null, { observe: 'response' });
  }

  public activeClient$(
    clientID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/actives`;

    return this.http.patch(url, null, { observe: 'response' });
  }

  sendCsvFile$(
    dataToGenerateReportVO: DataToGenerateReportVO
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/exports/csv`;

    return this.http.post(url, dataToGenerateReportVO, { observe: 'response' });
  }
}
