import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatusEnum } from '@qaroni-app/application/auth';
import {
  Merchant,
  MerchantDataSource,
  MerchantService,
  MerchantSnackbars
} from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { ConfirmationDialogData } from '@qaroni-core/types/confirmation-dialog/confirmation-dialog-data';
import { GeneralConstants } from '@qaroni-shared/constants/GeneralConstants';
import { KeysConstants } from '@qaroni-shared/constants/KeysConstants';
import { Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'qaroni-merchants-table',
  templateUrl: './merchants-table.component.html',
  styleUrls: ['./merchants-table.component.scss'],
})
export class MerchantsTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public merchant$: Observable<Merchant> = this.merchantService
    .getMerchant$()
    .pipe(shareReplay(1));

  private subs: Subscription = new Subscription();

  @Input() showPaginator = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Merchant>;
  public dataSource: MerchantDataSource;

  public displayedColumns: string[] = [
    'creationDate',
    'hasBonuses',
    'name',
    'nif',
    'phone',
    'email',
    'budgetLevel',
    'status',
    'actions',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private merchantService: MerchantService,
    private allApp: AllAppService
  ) {}

  ngOnInit(): void {
    this.dataSource = new MerchantDataSource(
      this.route,
      this.router,
      this.merchantService
    );

    this.subs.add(this.merchant$.subscribe(this.getMerchant));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public detailsTooltip(merchant: Merchant): string {
    let tooltip = 'Ver detalles';
    if (merchant?.tradename) {
      tooltip += ' de ' + merchant.tradename;
    }
    return tooltip;
  }

  public showViewPdf(merchant: Merchant): boolean {
    return (
      merchant &&
      merchant[KeysConstants.KEY_SIGNATURE_ID] &&
      merchant.status === UserStatusEnum.ACTIVE
    );
  }

  public openPdfContract(merchant: Merchant): void {
    if (merchant[KeysConstants.KEY_SIGNATURE_ID]) {
      const url: string =
        GeneralConstants.URL_FIRMARY_DOCUMENTS +
        merchant[KeysConstants.KEY_SIGNATURE_ID];
      window.open(url);
    } else {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          MerchantSnackbars.failureOpenContractBecauseDontExists.message
        ),
        MerchantSnackbars.failureOpenContractBecauseDontExists.closeBtn,
        MerchantSnackbars.failureOpenContractBecauseDontExists.config
      );
    }
  }

  unlockNextStop(merchat: Merchant): void {
    const topeUno = 1;
    const topeDos = 2;
    if (merchat && merchat.merchantId && merchat.name) {
      if (topeDos === merchat.budgetLevel) {
        this.allApp.snackbar.open(
          this.allApp.translate.instant(
            MerchantSnackbars.failureUnlockNextStopToTwoExists.message
          ),
          MerchantSnackbars.failureUnlockNextStopToTwoExists.closeBtn,
          MerchantSnackbars.failureUnlockNextStopToTwoExists.config
        );
      } else if (topeUno === merchat.budgetLevel) {
        this.openConfirmationDialog(merchat.merchantId, merchat.name);
      }
    }
  }

  openConfirmationDialog(merchantId: number, name): void {
    const confirmationData: ConfirmationDialogData = {
      title: this.allApp.translate.instant(`¿Desbloquear segundo tope?`),

      message:
        this.allApp.translate.instant(
          `Vas a desbloquear el segundo tope para el comercio: `
        ) + name,

      confirmMatIcon: `open_in_browser`,
      confirmText: this.allApp.translate.instant(`Sí, desbloquear`),
    };

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '700px';
    matDialogConfig.maxWidth = '90vw';
    matDialogConfig.panelClass = 'style-close-dialog';
    matDialogConfig.data = confirmationData;

    const dialog = this.allApp.dialog.open(
      ConfirmationDialogComponent,
      matDialogConfig
    );

    this.subs.add(
      dialog.afterClosed().subscribe((confirmation) => {
        if (confirmation === true) {
          this.enableLoading();
          this.merchantService.unlockNextStop(merchantId);
        }
      })
    );
  }

  private getMerchant = (merchant: Merchant): void => {
    this.disableLoading();
    if (merchant) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          MerchantSnackbars.successUnlockNextStop.message
        ),
        MerchantSnackbars.successUnlockNextStop.closeBtn,
        MerchantSnackbars.successUnlockNextStop.config
      );

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          MerchantSnackbars.failureUnlockNextStop.message
        ),
        MerchantSnackbars.failureUnlockNextStop.closeBtn,
        MerchantSnackbars.failureUnlockNextStop.config
      );
    }
  }
}
