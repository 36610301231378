<div
  class="mat-h2 my-7 text-center font-weight-light"
  *ngIf="paginator?.length === 0"
>
  {{ "No hay consumidores que mostrar" | translate }}
</div>

<div class="table-responsive" [ngClass]="{ 'd-none': paginator?.length === 0 }">
  <table mat-table matSort class="full-width-table" aria-label="Clients">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Nombre" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.firstName + " " + row?.lastName }}
      </td>
    </ng-container>

    <!-- Document Column -->
    <ng-container matColumnDef="document">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Documento" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row?.document }}</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Correo electrónico" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row?.email }}</td>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Teléfono" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row?.phone }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Estado" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.status | userStatus | translate }}
      </td>
    </ng-container>

    <!-- PDF Column -->
    <ng-container matColumnDef="pdf">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Bono en PDF" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ pdfColumnString(row) | translate }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-menu>
          <button mat-menu-item><mat-icon>lock</mat-icon> una</button>
          <mat-divider></mat-divider>
          <button><mat-icon>exit_to_app</mat-icon> dos</button>
        </mat-menu>

        <div
          class="d-flex"
          [ngClass]="{
            'justify-content-between': hasCards(row),
            'justify-content-end': !hasCards(row)
          }"
        >
          <ng-container *ngIf="row?.status === UserStatusEnum.ACTIVE">
            <button
              mat-mini-fab
              color="none"
              class="mat-elevation-z0 m-1"
              [matTooltip]="'Desactivar' | translate"
              matTooltipPosition="above"
              (click)="cancelClient(row)"
            >
              <mat-icon>report_off</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="row?.status === UserStatusEnum.CANCELLED">
            <button
              mat-mini-fab
              color="light-green"
              class="mat-elevation-z0 m-1"
              [matTooltip]="'Activar' | translate"
              matTooltipPosition="above"
              (click)="activeClient(row)"
            >
              <mat-icon>done</mat-icon>
            </button>
          </ng-container>
          <button
            mat-mini-fab
            color="none"
            class="mat-elevation-z0 m-1"
            [matTooltip]="'Eliminar' | translate"
            matTooltipPosition="before"
            (click)="deleteClient(row)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="none"
            class="mat-elevation-z0 m-1"
            type="button"
            [routerLink]="['/dashboard/clients', row?.clientId, 'cards']"
            [matTooltip]="detailsTooltip(row)"
            matTooltipPosition="before"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div [ngClass]="{ 'd-none': paginator?.length === 0 || !showPaginator }">
  <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
