import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardPdfDataDialog } from '@qaroni-app/application/cards/types/card-pdf-data-dialog';

@Component({
  selector: 'qaroni-card-pdf-dialog',
  templateUrl: './card-pdf-dialog.component.html',
  styleUrls: ['./card-pdf-dialog.component.scss'],
})
export class CardPdfDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CardPdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CardPdfDataDialog
  ) {}

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  public onClick(): void {
    this.dialogRef.close(true);
  }
}
