import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { PaginationLinks } from '@qaroni-core/types';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DataToGenerateReportVO } from '../components/models/DataToGenerateReportVO';
import { Client } from '../types/client';
import { ClientSnackbars } from '../types/client-snackbars.config';
import { ClientHttpService } from './client-http.service';
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  protected readonly clientSubject = new Subject<Client>();
  protected readonly clientsSubject = new Subject<Client[]>();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();
  public clientInfo: string = null;

  constructor(
    private allApp: AllAppService,
    private clientHttp: ClientHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public setClientInfo(clientInfo: string): void {
    this.clientInfo = clientInfo;
  }

  public getClientInfo(): string {
    return this.clientInfo;
  }

  // ==========================================================================================
  // Get Client
  // ==========================================================================================

  public getClient$(): Observable<Client> {
    return this.clientSubject.asObservable();
  }

  public getClient(): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      this.enableLoading();
      this.clientHttp
        .getClient$(this.oAuthStorage.getClientID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextGetClient, error: this.errorGetClient });
    }
  }

  private nextGetClient = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const client: Client = data.body.result[0];
      this.clientSubject.next(client);
    } else {
      this.clientSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetClient = (error: HttpErrorResponse): void => {
    this.clientSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Update Client
  // ==========================================================================================

  public updateClient(client: Client): void {
    if (this.oAuthStorage.hasOAuth && this.oAuthStorage.getClientID) {
      this.enableLoading();
      this.clientHttp
        .updateClient$(this.oAuthStorage.getClientID, client)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextUpdateClient,
          error: this.errorUpdateClient,
        });
    }
  }

  private nextUpdateClient = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const client: Client = data.body.result[0];
      this.clientSubject.next(client);
    } else {
      this.clientSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorUpdateClient = (error: HttpErrorResponse): void => {
    this.clientSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Clients
  // ==========================================================================================

  public getClients$(): Observable<Client[]> {
    return this.clientsSubject.asObservable();
  }

  public resetClients(): void {
    return this.clientsSubject.next(null);
  }

  public getClients(params?: Params): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.clientHttp
        .getClients$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({ next: this.nextGetClients, error: this.errorGetClients });
    }
  }

  private nextGetClients = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const clients: Client[] = data.body.result;
      this.clientsSubject.next(clients);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.clientsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetClients = (error: HttpErrorResponse): void => {
    this.clientsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }

  // ==========================================================================================
  // Cancel Client
  // ==========================================================================================
  public cancelClient(clientID: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.clientHttp
        .cancelClient$(clientID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextCancelClient,
          error: this.errorCancelClient,
        });
    }
  }

  private nextCancelClient = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.clientSubject.next(null);
      this.allApp.snackbar.open(
        ClientSnackbars.successCancelled.message,
        ClientSnackbars.successCancelled.closeBtn,
        ClientSnackbars.successCancelled.config
      );
    } else {
      this.clientSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorCancelClient = (error: HttpErrorResponse): void => {
    this.clientSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Active Client
  // ==========================================================================================
  public activeClient(clientID: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.clientHttp
        .activeClient$(clientID)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextActiveClient,
          error: this.errorActiveClient,
        });
    }
  }

  private nextActiveClient = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.clientSubject.next(null);
      this.allApp.snackbar.open(
        ClientSnackbars.successActivated.message,
        ClientSnackbars.successActivated.closeBtn,
        ClientSnackbars.successActivated.config
      );
    } else {
      this.clientSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorActiveClient = (error: HttpErrorResponse): void => {
    this.clientSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  public sendCsvFile(dataToGenerateReportVO: DataToGenerateReportVO): any {
    this.enableLoading();
    return this.clientHttp
      .sendCsvFile$(dataToGenerateReportVO)
      .pipe(finalize(() => this.disableLoading()));
  }

  // ==========================================================================================
  // Get Client by Id
  // ==========================================================================================
  public getClientById(clientId: number | string): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.clientHttp
        .getClient$(clientId)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetClientById,
          error: this.errorGetClientById,
        });
    }
  }

  private nextGetClientById = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const client: Client = data.body.result[0];
      this.clientSubject.next(client);
    } else {
      this.clientSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetClientById = (error: HttpErrorResponse): void => {
    this.clientSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }
}
