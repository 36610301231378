import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClientTransactionDataSource,
  Transaction
} from '@qaroni-app/application/transactions';
import { TransactionService } from '@qaroni-app/application/transactions/services/transaction.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qaroni-client-transactions-table',
  templateUrl: './client-transactions-table.component.html',
  styleUrls: ['./client-transactions-table.component.scss'],
})
export class ClientTransactionsTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private subs: Subscription = new Subscription();

  submitting = false;

  @Input() showPaginator = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Transaction>;
  public dataSource: ClientTransactionDataSource;

  public displayedColumns: string[] = [
    'creationDate',
    'code',
    'fullAmount',
    'amount',
    'description',
    'type',
    'result',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransactionService
  ) {}

  ngOnInit(): void {
    this.dataSource = new ClientTransactionDataSource(
      this.route,
      this.router,
      this.transactionService
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
