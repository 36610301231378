import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { DataToGenerateReportVO } from '@qaroni-app/application/clients/components/models/DataToGenerateReportVO';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';
import { Merchant } from '../types/merchant';
import { MerchantReportData } from '../types/merchant-report-data';

@Injectable({
  providedIn: 'root',
})
export class MerchantHttpService {
  constructor(private http: HttpClient) {}

  public getMerchants$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/dashboards`;

    return this.http.get(url, { observe: 'response', params });
  }

  public getMerchant$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public updateMerchant$(
    merchantID: number | string,
    merchant: Merchant
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}`;

    return this.http.patch(url, merchant, { observe: 'response' });
  }

  public addImage$(
    merchantID: number | string,
    dataMultipart: FormData
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/images`;

    return this.http.post(url, dataMultipart, { observe: 'response' });
  }

  public deleteImage$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/images`;

    return this.http.delete(url, { observe: 'response' });
  }

  public cancelMerchant$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/cancels`;

    return this.http.patch(url, null, { observe: 'response' });
  }

  public activeMerchant$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/actives`;

    return this.http.patch(url, null, { observe: 'response' });
  }

  sendMerchantsCsvFile$(
    dataToGenerateReportVO: DataToGenerateReportVO
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/exports/csv`;
    return this.http.post(url, dataToGenerateReportVO, { observe: 'response' });
  }

  sendReportByIAE$(
    reportData: MerchantReportData
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/reports/iaes`;

    return this.http.post(url, reportData, { observe: 'response' });
  }

  sendReportByCouncils$(
    reportData: MerchantReportData
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/reports/councils`;

    return this.http.post(url, reportData, { observe: 'response' });
  }

  public unlockNextStop$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}/budgets`;

    return this.http.patch(url, null, { observe: 'response' });
  }
}
