import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { MerchantTypeEnum } from '@qaroni-core/entities/merchant';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { PaginationLinks, TotalAmounts } from '@qaroni-core/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Transaction, TransactionOperation } from '../types/transaction';
import { TransactionReportData } from '../types/transaction-report-data';
import { TransactionResultEnum } from '../types/transaction-result.enum';
import { TransactionSnackbars } from '../types/transaction-snackbars.config';
import { TransactionTypeEnum } from '../types/transaction-type.enum';
import { TransactionHttpService } from './transaction-http.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  protected readonly transactionsSubject = new Subject<Transaction[]>();
  protected readonly transactionSubject = new Subject<Transaction>();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();

  protected readonly totalAmountsSubject = new Subject<TotalAmounts>();
  protected readonly totalAmountsCultureSubject =
    new BehaviorSubject<TotalAmounts>(null);
  protected readonly totalAmountsHostelrySubject =
    new BehaviorSubject<TotalAmounts>(null);
  protected readonly totalAmountsShopSubject =
    new BehaviorSubject<TotalAmounts>(null);
  protected readonly totalAmountsSalonSubject =
    new BehaviorSubject<TotalAmounts>(null);
  protected readonly totalAmountsTaxiSubject =
    new BehaviorSubject<TotalAmounts>(null);

  protected readonly transactionReportByParamsSubject = new Subject<boolean>();

  constructor(
    private allApp: AllAppService,
    private transactionHttp: TransactionHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  public isAdd(transaction: Transaction): boolean {
    return transaction?.type === TransactionTypeEnum.ADD;
  }

  public isSale(transaction: Transaction): boolean {
    return transaction?.type === TransactionTypeEnum.SALE;
  }

  public isAccepted(transaction: Transaction): boolean {
    return transaction?.result === TransactionResultEnum.ACCEPTED;
  }

  public isCancelled(transaction: Transaction): boolean {
    return transaction?.result === TransactionResultEnum.CANCELLED;
  }

  public isDenied(transaction: Transaction): boolean {
    return transaction?.result === TransactionResultEnum.DENIED;
  }

  // ==========================================================================================
  // Get Transactions
  // ==========================================================================================

  public getTransactions$(): Observable<Transaction[]> {
    return this.transactionsSubject.asObservable();
  }

  public resetTransactions(): void {
    return this.transactionsSubject.next(null);
  }

  public getTransactions(merchantID: number | string, params?: Params): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTransactions$(merchantID, params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTransactions,
          error: this.errorGetTransactions,
        });
    }
  }

  private nextGetTransactions = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const transactions: Transaction[] = data.body.result;
      this.transactionsSubject.next(transactions);
      const totalAmounts: TotalAmounts = data.body.included;
      this.totalAmountsSubject.next(totalAmounts);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.transactionsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTransactions = (error: HttpErrorResponse): void => {
    this.transactionsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Create Transactions
  // ==========================================================================================

  public getTransaction$(): Observable<Transaction> {
    return this.transactionSubject.asObservable();
  }

  public createTransaction(
    merchantID: number | string,
    transaction: TransactionOperation
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .createTransaction$(merchantID, transaction)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextCreateTransaction,
          error: this.errorCreateTransaction,
        });
    }
  }

  private nextCreateTransaction = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus201(data)) {
      const transaction: Transaction = data.body.result[0];
      this.transactionSubject.next(transaction);
    } else {
      this.transactionSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorCreateTransaction = (error: HttpErrorResponse): void => {
    this.transactionSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Total Amounts
  // ==========================================================================================

  public getTotalAmounts$(): Observable<TotalAmounts> {
    return this.totalAmountsSubject.asObservable();
  }

  public getTotalAmounts(params?: Params): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTotalAmounts$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTotalAmounts,
          error: this.errorGetTotalAmounts,
        });
    }
  }

  private nextGetTotalAmounts = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const totalAmounts: TotalAmounts = data.body.result[0];
      this.totalAmountsSubject.next(totalAmounts);
    } else {
      this.totalAmountsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTotalAmounts = (error: HttpErrorResponse): void => {
    this.totalAmountsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Total Amounts Culture
  // ==========================================================================================

  public getTotalAmountsCulture$(): Observable<TotalAmounts> {
    return this.totalAmountsCultureSubject.asObservable();
  }

  public getTotalAmountsCulture(
    params: Params = { type: MerchantTypeEnum.CULTURE }
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTotalAmounts$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTotalAmountsCulture,
          error: this.errorGetTotalAmountsCulture,
        });
    }
  }

  private nextGetTotalAmountsCulture = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const totalAmounts: TotalAmounts = data.body.result[0];
      this.totalAmountsCultureSubject.next(totalAmounts);
    } else {
      this.totalAmountsCultureSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTotalAmountsCulture = (error: HttpErrorResponse): void => {
    this.totalAmountsCultureSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Total Amounts Hostelry
  // ==========================================================================================

  public getTotalAmountsHostelry$(): Observable<TotalAmounts> {
    return this.totalAmountsHostelrySubject.asObservable();
  }

  public getTotalAmountsHostelry(
    params: Params = { type: MerchantTypeEnum.HOSTELRY }
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTotalAmounts$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTotalAmountsHostelry,
          error: this.errorGetTotalAmountsHostelry,
        });
    }
  }

  private nextGetTotalAmountsHostelry = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const totalAmounts: TotalAmounts = data.body.result[0];
      this.totalAmountsHostelrySubject.next(totalAmounts);
    } else {
      this.totalAmountsHostelrySubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTotalAmountsHostelry = (error: HttpErrorResponse): void => {
    this.totalAmountsHostelrySubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Total Amounts Shop
  // ==========================================================================================

  public getTotalAmountsShop$(): Observable<TotalAmounts> {
    return this.totalAmountsShopSubject.asObservable();
  }

  public getTotalAmountsShop(
    params: Params = { type: MerchantTypeEnum.SHOP }
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTotalAmounts$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTotalAmountsShop,
          error: this.errorGetTotalAmountsShop,
        });
    }
  }

  private nextGetTotalAmountsShop = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const totalAmounts: TotalAmounts = data.body.result[0];
      this.totalAmountsShopSubject.next(totalAmounts);
    } else {
      this.totalAmountsShopSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTotalAmountsShop = (error: HttpErrorResponse): void => {
    this.totalAmountsShopSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Total Amounts Salon
  // ==========================================================================================

  public getTotalAmountsSalon$(): Observable<TotalAmounts> {
    return this.totalAmountsSalonSubject.asObservable();
  }

  public getTotalAmountsSalon(
    params: Params = { type: MerchantTypeEnum.SALON }
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTotalAmounts$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTotalAmountsSalon,
          error: this.errorGetTotalAmountsSalon,
        });
    }
  }

  private nextGetTotalAmountsSalon = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const totalAmounts: TotalAmounts = data.body.result[0];
      this.totalAmountsSalonSubject.next(totalAmounts);
    } else {
      this.totalAmountsSalonSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTotalAmountsSalon = (error: HttpErrorResponse): void => {
    this.totalAmountsSalonSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Total Amounts Taxi
  // ==========================================================================================

  public getTotalAmountsTaxi$(): Observable<TotalAmounts> {
    return this.totalAmountsTaxiSubject.asObservable();
  }

  public getTotalAmountsTaxi(
    params: Params = { type: MerchantTypeEnum.TAXI }
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTotalAmounts$(params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTotalAmountsTaxi,
          error: this.errorGetTotalAmountsTaxi,
        });
    }
  }

  private nextGetTotalAmountsTaxi = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const totalAmounts: TotalAmounts = data.body.result[0];
      this.totalAmountsTaxiSubject.next(totalAmounts);
    } else {
      this.totalAmountsTaxiSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTotalAmountsTaxi = (error: HttpErrorResponse): void => {
    this.totalAmountsTaxiSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }

  // ==========================================================================================
  // Transaction Report
  // ==========================================================================================

  public getTransactionReportByParams$(): Observable<boolean> {
    return this.transactionReportByParamsSubject.asObservable();
  }

  public sendTransactionReportByParams(
    merchantId: number,
    reportData: TransactionReportData
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .sendTransactionReportByParams$(merchantId, reportData)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextSendTransactionReportByParams,
          error: this.errorSendTransactionReportByParams,
        });
    }
  }

  private nextSendTransactionReportByParams = (
    data: HttpResponse<any>
  ): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus204(data)) {
      this.transactionReportByParamsSubject.next(true);
    } else {
      this.transactionReportByParamsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorSendTransactionReportByParams = (
    error: HttpErrorResponse
  ): void => {
    this.transactionReportByParamsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Return transaction
  // ==========================================================================================

  public returnTransaction(merchantId: number, transactionId: number): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .returnTransaction$(merchantId, transactionId)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextReturnTransaction,
          error: this.errorReturnTransaction,
        });
    }
  }

  private nextReturnTransaction = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          TransactionSnackbars.successReturnTransaction.message
        ),
        TransactionSnackbars.successReturnTransaction.closeBtn,
        TransactionSnackbars.successReturnTransaction.config
      );
      const transaction: Transaction = data.body.result[0];
      this.transactionSubject.next(transaction);
    } else {
      this.transactionSubject.next(null);
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          TransactionSnackbars.failureReturnTransaction.message
        ),
        TransactionSnackbars.failureReturnTransaction.closeBtn,
        TransactionSnackbars.failureReturnTransaction.config
      );
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorReturnTransaction = (error: HttpErrorResponse): void => {
    this.transactionSubject.next(null);
    if (
      this.commonsHttp.errorsHttp.isControlledError(error) &&
      this.commonsHttp.errorsHttp.isErrorCode(
        error,
        'E0011',
        'Could not create resource.',
        'Ya existe una devolución para esta operación'
      )
    ) {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          TransactionSnackbars.failureReturnTransactionThereIsReturn.message
        ),
        TransactionSnackbars.failureReturnTransactionThereIsReturn.closeBtn,
        TransactionSnackbars.failureReturnTransactionThereIsReturn.config
      );
    } else {
      this.allApp.snackbar.open(
        this.allApp.translate.instant(
          TransactionSnackbars.failureReturnTransaction.message
        ),
        TransactionSnackbars.failureReturnTransaction.closeBtn,
        TransactionSnackbars.failureReturnTransaction.config
      );
    }
    this.commonsHttp.errorsHttp.communication(error);
  }

  public getTransactionsByClient(
    clientID: number | string,
    params?: Params
  ): void {
    if (this.oAuthStorage.hasOAuth) {
      this.enableLoading();
      this.transactionHttp
        .getTransactionsByClient$(clientID, params)
        .pipe(finalize(() => this.disableLoading()))
        .subscribe({
          next: this.nextGetTransactionsByClient,
          error: this.errorGetTransactionsByClient,
        });
    }
  }

  private nextGetTransactionsByClient = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const transactions: Transaction[] = data.body.result;
      this.transactionsSubject.next(transactions);
      const totalAmounts: TotalAmounts = data.body.included;
      this.totalAmountsSubject.next(totalAmounts);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.transactionsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetTransactionsByClient = (error: HttpErrorResponse): void => {
    this.transactionsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }
}
