import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatusEnum } from '@qaroni-app/application/auth';
import { CardService } from '@qaroni-app/application/cards/services/card.service';
import { Card } from '@qaroni-app/application/cards/types/card';
import {
  Client,
  ClientDataSource,
  ClientService
} from '@qaroni-app/application/clients';
import { AllAppService } from '@qaroni-core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'qaroni-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
})
export class ClientsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showPaginator = true;

  @Output() cancel: EventEmitter<Client> = new EventEmitter();
  @Output() active: EventEmitter<Client> = new EventEmitter();
  @Output() delete: EventEmitter<Client> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Client>;
  public dataSource: ClientDataSource;

  public displayedColumns: string[] = [
    'name',
    'document',
    'email',
    'phone',
    'status',
    'pdf',
    'actions',
  ];

  public UserStatusEnum = UserStatusEnum;

  private subs: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private cardService: CardService,
    private allApp: AllAppService
  ) {}

  ngOnInit(): void {
    this.dataSource = new ClientDataSource(
      this.route,
      this.router,
      this.clientService
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public cancelClient(client: Client): void {
    this.cancel.emit(client);
  }

  public activeClient(client: Client): void {
    this.active.emit(client);
  }

  public deleteClient(client: Client): void {
    this.delete.emit(client);
  }

  public hasCards(client: Client): boolean {
    return !!client?.cards?.length;
  }

  public isCardStatic(card: Card): boolean {
    return this.cardService.isCardStatic(card);
  }

  public pdfColumnString(client: Client): string {
    if (this.hasCards(client)) {
      const result = client.cards.filter((c) => this.isCardStatic(c));
      return result && result.length > 0 ? 'Si' : 'No';
    } else {
      return 'No tiene bonos';
    }
  }

  public detailsTooltip(client: Client): string {
    let tooltip = this.allApp.translate.instant('Ver detalles');
    if (client && client.firstName) {
      tooltip += this.allApp.translate.instant(' de ') + client.firstName;
      if (client.lastName) {
        tooltip += ' ' + client.lastName;
      }
    }
    return tooltip;
  }
}
