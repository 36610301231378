<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="outline" class="ff-no-bottom-space moz-suffix">
    <mat-label>{{ "Introduce un rango de fechas" | translate }}</mat-label>
    <mat-date-range-input
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
    >
      <input
        matStartDate
        [placeholder]="'Inicio' | translate"
        formControlName="dateFrom"
      />
      <input
        matEndDate
        [placeholder]="'Fin' | translate"
        formControlName="dateTo"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker
      #picker
      (closed)="closeDatepicker()"
      disabled="false"
    ></mat-date-range-picker>
  </mat-form-field>
</form>
