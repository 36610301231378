<div class="mat-h2 my-7 text-center font-weight-light" *ngIf="!showTable">
  {{ "No hay tarjetas que mostrar" | translate }}
</div>

<div class="table-responsive" [ngClass]="{ 'd-none': !showTable }">
  <table mat-table matSort class="full-width-table" aria-label="Cards">
    <!-- CreationDate Column -->
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Fecha de alta" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.creationDate | date: "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdateDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Fecha de modificación" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.lastUpdateDate | date: "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Tipo" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.type | cardType | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="isStatic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Estática" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ isCardStatic(row) ? "Si" : ("No" | translate) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "Saldo" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.balance | integerToCurrency | currency }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-menu>
          <button mat-menu-item><mat-icon>lock</mat-icon> una</button>
          <mat-divider></mat-divider>
          <button><mat-icon>exit_to_app</mat-icon> dos</button>
        </mat-menu>

        <div class="d-flex justify-content-end">
          <button
            mat-mini-fab
            [color]="getColor(row)"
            class="mat-elevation-z0 m-1"
            [matTooltip]="getAcctionPdfTitle(row)"
            matTooltipPosition="above"
            (click)="downloadPdf(row)"
            [disabled]="submitting"
          >
            <mat-icon>{{ getAcctionIconByCard(row) }}</mat-icon>
          </button>

          <button
            mat-mini-fab
            [color]="'light-blue'"
            class="mat-elevation-z0 m-1 ml-3"
            [matTooltip]="'Refrescar wallet' | translate"
            matTooltipPosition="above"
            (click)="refreshWallet(row)"
            [disabled]="submitting"
          >
            <mat-icon> refresh </mat-icon>
          </button>

          <button
            mat-mini-fab
            color="none"
            class="mat-elevation-z0 m-1"
            type="button"
            [matTooltip]="'Ver operaciones' | translate"
            matTooltipPosition="before"
            (click)="viewTransactionsByCardId(row)"
          >
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div [ngClass]="{ 'd-none': paginator?.length === 0 || !showPaginator }">
  <mat-paginator #paginator [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
